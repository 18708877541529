.customField {
  margin-bottom: 24px;

  &:last-child {
    margin-bottom: 0;
  }

  @media (--viewportMedium) {
    margin-bottom: 32px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.checkboxContainer {
  display: flex;
  flex-wrap: wrap;
}

.checkboxGrid {
  display: grid;
  grid-template-columns: repeat(5, 1fr); /* 5 checkboxes per row */
  gap: 10px; /* Adjust spacing */
}

.checkboxGrid label {
  display: flex;
  align-items: center;
  gap: 5px;
}
